import React from "react";
import Service from "../components/Service";
import Team from "../components/Team";
import Package from "../components/Package";
function Home() {
  const basic = [
    `Marketing strategy`,
    `Graphic design`,
    `social media management`,
  ];
  const premium = [`All In Standard Package`, `Branding`, `Tiktok followup`];
  const standard = [`All In Basic Package`, `photography`, `videography`];
  return (
    <div className="home_page_wrapper">
      <div className="hero_section_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 hero_section">
              <div className="section_content">
                <h1 className="section_header">
                  WE WORK UNTIL YOU REACH YOUR GOAL
                </h1>
                <p className="section_description">
                  We are a full service digital marketing agency that can handle
                  all your online marketing needs,from SEO to social media and
                  everything in between.
                </p>
                <a href="/#about">
                  <button className="section_btn hero_btn">Learn More</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services_section" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_content">
                <p className="section_subtitle">Services</p>
                <h2 className="section_title services_title">
                  Everything We Offer
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <Service
                    img={require("..//assets/images/marketing.png")}
                    img2={require("..//assets/images/arrow.png")}
                    title="Marketing Strategy"
                    description="Crafting frameworks to evaluate, innovate, and build competitive strategies for organizations."
                  />
                </div>
                <div className="col-lg-4">
                  <Service
                    img={require("..//assets/images/graphic_design.png")}
                    img2={require("..//assets/images/arrow.png")}
                    title="Graphic Design"
                    description="Transform ideas into visually stunning realities with our expert graphic design services."
                  />
                </div>
                <div className="col-lg-4">
                  <Service
                    img={require("..//assets/images/social.png")}
                    img2={require("..//assets/images/arrow.png")}
                    title="Social Media"
                    description="Elevate sales, drive quality traffic via social media: Instagram, Facebook, and more."
                  />
                </div>
                <div className="col-lg-4">
                  <Service
                    img={require("..//assets/images/video.png")}
                    img2={require("..//assets/images/arrow.png")}
                    title="Videography / Photography"
                    description="Elevate your brand with  video marketing, storytelling, and high-quality photography services."
                  />
                </div>
                <div className="col-lg-4">
                  <Service
                    img={require("..//assets/images/copywriting.png")}
                    img2={require("..//assets/images/arrow.png")}
                    title="Copywriting"
                    description="Crafting compelling copies: captions, sales letters, ads, tutorials, with keyword precision."
                  />
                </div>
                <div className="col-lg-4">
                  <Service
                    img={require("..//assets/images/branding.png")}
                    img2={require("..//assets/images/arrow.png")}
                    title="Branding"
                    description="Our team will renovate your full branding by adopting a well-built strategy that aligns with your business."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about_us_section" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row mobile_about">
                <div className="col-lg-6">
                  <div className="about_img">
                    <img
                      src={require("..//assets/images/about_img.png")}
                      alt="office meeting"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="section_content">
                  <p className="section_subtitle">About</p>
                    <h3 className="section_header">More About BENA Social</h3>
                    <p className="section_description">
                      BENA is designed in order to show the important of social
                      media in the business world.We are full-services digital
                      marketing agency that can handle all your online marketing
                      needs,from SEO to social media and everything in between.
                      <br /> <br />
                      Whenever we handle your business,rest assured that we will
                      handle it as if it is our own.This is our Moto “We Work
                      until you reach your goals”
                    </p>
                    <a href="https://wa.me/96171506079" target="_blank" rel="noreferrer">
                      <button className="section_btn about_btn">
                        Work With Us
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="team_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_content">
                <p className="section_subtitle">Team</p>
                <h2 className="section_title services_title">
                  Our Expert Team Members
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <Team
                    img={require("..//assets/images/team1.png")}
                    name="Rebecca Traboulsi"
                    position="CEO & co-founder"
                    role="She puts the BE in BENA.A law graduate.She has 5 years of experience in designing solo and in collaboration with companies.Today,she leads the Design Department of BENA."
                  />
                </div>
                <div className="col-lg-4 col-md-4">
                  <Team
                    img={require("..//assets/images/team2.png")}
                    name="Daniel AbdelKhalek"
                    position="co-founder"
                    role="Head of Photography and videography department.he is a Cinametography graduate.He worked in famous talk shows,talent shows and short movies.He also worked as a freelance director,editor,camera operator,photographer for 5 years in different fileds,adn was an acting teacher for 2 years."
                  />
                </div>
                <div className="col-lg-4 col-md-4">
                  <Team
                    img={require("..//assets/images/team3.png")}
                    name="Nagham Yehya"
                    position="CEO & co-founder"
                    role="She puts the NA in BENA.Journalist and Comunication stragtegist.She has more than 5 years of experience in TV presenting,writing articles,and social media management.Today she leads the marketing and content writing department of BENA."
                  />
                </div>
                <div className="col-lg-4 col-md-4">
                  <Team
                    img={require("..//assets/images/team4.png")}
                    name="Tia Yehya"
                    position="Graphic Designer"
                    role="With two years of experience in graphic design, Tia has quickly emerged as a versatile and innovative professional in the field. Her passion for visual storytelling and attention to detail have fueled their ability to create captivating designs that effectively communicate clients' messages. Continuously honing their skills, Tia is committed to pushing the boundaries of creativity and delivering impactful solutions across various mediums."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="packages_section" id="packages">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_content">
                <p className="section_subtitle">Packages</p>
                <h2 className="section_title services_title">
                  Explore Our Packages
                </h2>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4">
                  <Package
                    title="Basic"
                    description="Elevate your brand with essential social media setup."
                    points={basic}
                  />
                </div>
                <div className="col-lg-4 col-md-4">
                  <Package
                    title="Premium"
                    description="Maximize performance with advanced social media insights."
                    points={premium}
                  />
                </div>
                <div className="col-lg-4 col-md-4">
                  <Package
                    title="Standard"
                    description="Expand your online presence using advanced social media tactics."
                    points={standard}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="choose_us_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_content">
                <h4 className="section_title">
                  Why Choose Us
                </h4>
                <p className="section-description">The reason why we will be the best partner for your business</p>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="choose_us_card">
                    <div className="icon">
                      <img
                        src={require("..//assets/images/icon1.png")}
                        alt="icon"
                      />
                    </div>
                    <h6 className="choose_us_title">Why Us</h6>
                    <p className="choose_us_description">
                      Full-services digital marketing agency to accelerate your
                      business growth and get you more targeted customer.
                    </p>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="choose_us_card">
                    <div className="icon">
                      <img
                        src={require("..//assets/images/icon2.png")}
                        alt="icon"
                      />
                    </div>
                    <h6 className="choose_us_title">Our Vision</h6>
                    <p className="choose_us_description">
                      Working to inspire the spirit,penetrate the heart,and
                      ignite the mind,with compelling digital marketing messages
                      that deliver results.
                    </p>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="choose_us_card">
                    <div className="icon">
                      <img
                        src={require("..//assets/images/icon3.png")}
                        alt="icon"
                      />
                    </div>
                    <h6 className="choose_us_title">Our Mission</h6>
                    <p className="choose_us_description">
                      To help our clients grow their businesses online so they
                      can get more customers and increase their profits.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cta_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <h6 className="cta_title">Choose Wisely...Contact Us</h6>
                </div>
                <div className="col-lg-6">
                <a href="https://wa.me/96171506079" target="_blank" rel="noreferrer">
                    <button className="section_btn cta_section_btn">
                      Book A Call
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
