import React,{useState} from "react";

function Team(props) {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  return (
    <div className="team_card" onClick={toggleDetails}>
      <div className="team_top">
        <img src={require("..//assets/images/teamWave1.png")} alt="wave" />
      </div>
      <div className="content_div">
      <div className="team_img_div">
        <img src={props.img} alt="Member" className="team_img" height="145px" />
      </div>
      <div className="team_content">
        <h5 className="name">{props.name}</h5>
        <p className="position">{props.position}</p>
        {showDetails && (
            <p className="role_details mt-4 px-2">
             
              {props.role}
            </p>
          )}
      </div>
      </div>
    </div>
  );
}

export default Team;
