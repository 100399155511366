import React from "react";
function Package(props) {
  const { title, description, points } = props;

  const cardClassName = `package_card_wrapper ${title === "Premium" ? "premium" : ""}`;
  return (
    <div className={cardClassName}>
      <div className="popular">
        <p>Most Popular</p>
      </div>

      <div className="package_card">
        <div className="package_card_header">
          <h3 className="package_title">{title}</h3>
          <p className="package_subtitle">{description}</p>
        </div>
        <div className="packags_card_body">
          <ul className="package_list">
            {points.map((point, index) => (
              <>
                <li key={index}>
                  <img
                    src={require("..//assets/images/tick_icon.png")}
                    alt="tick icon"
                    className="tick_icon"
                  />
                  {point}
                </li>
                <div className="line"></div>
              </>
            ))}
          </ul>
        </div>
        <div className="packages_card_footer">
          <a href="https://wa.me/96171506079" target="_blank" rel="noreferrer">
            <button className="section_btn package_btn">Contact Us</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Package;
