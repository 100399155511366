import React from "react";

function Footer() {
  return (
    <div className="footer">
      <div className="footer_top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6">
                  <div className="footer_logo">
                    <img
                      src={require("..//assets/images/logo.png")}
                      alt="logo"
                      className="flogo"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="socials">
                    <a href="https://www.facebook.com/BENAsocialmedia?mibextid=2JQ9oc" target="_blank" rel="noreferrer">
                    <img
                      src={require("..//assets/images/facebook.png")}
                      alt="facebookicon"
                      className="social_icon"
                    />
                    </a>
                    <a href="https://www.instagram.com/bena_social?igsh=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer">
                    <img
                      src={require("..//assets/images/insta.png")}
                      alt="instaIcon"
                      className="social_icon"
                    />
                    </a>
                    <a href="https://wa.me/96176740139" target="_blank" rel="noreferrer">
                     <img
                      src={require("..//assets/images/whatsapp.png")}
                      alt="whatsappIcon"
                      className="social_icon"
                    />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <p className="copyrights">
          ALL Rights Reserved 2024. Done By{" "}
          <a href="https://growwithalaa.com" target="_blank" rel="noreferrer">Alaa Abi Ezzddine</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
