import React from "react";
function Service(props) {
  return (
    <div className="service_card_wrapper">
      {/* <Link to="/services">
      <div className="service_arrow">
        <img src={props.img2} alt="icon" className="arrow_img" />
      </div>
      </Link> */}
      <div className="service_card">
        <div className="service_card_content">
          <div className="service_icon">
            <img src={props.img} alt="icon" className="service_icon_img" />
          </div>
          <h4 className="service_card_title">{props.title}</h4>
          <p className="service_card_description">{props.description}</p>
        </div>
      </div>
    </div>
  );
}

export default Service;
