import React, { useState, useEffect,useRef } from "react";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const [showNav, setShowNav] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(""); 
  const navRef = useRef(null);
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setShowNav(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [navRef]);
  const toggleNavbar = () => {
    setShowNav(!showNav);
  };

  const hideMenu = () => {
    setShowNav(false);
  };

  return (
    <div>
      <div className="Header_page">
        <div className="container">
          <div className="nav_wrapper" ref={navRef}>
            <div className="logo_div">
              <img src={require("..//assets/images/logo.png")} alt="logo" />
            </div>
            <div className={`nav_links ${showNav ? "show" : ""}`}>
              <ul className="nav_list">
                <Link to="/" className={`nav_link ${activeLink === "/" ? "active" : ""}`} onClick={hideMenu}>
                  <li>Home</li>
                </Link>
                <a href="/#about" className={`nav_link ${activeLink === "/about" ? "active" : ""}`} onClick={hideMenu}>
                  <li>About</li>
                </a>
                <a href="/#packages" className={`nav_link ${activeLink === "/packages" ? "active" : ""}`} onClick={hideMenu}>
                  <li>Packages</li>
                </a>
                <a href="/#services" className={`nav_link ${activeLink === "/growth-accelerator" ? "active" : ""}`} onClick={hideMenu}>
                  <li>Services</li>
                </a>
              </ul>
            </div>
            <div className="nav_cta">
              <a href="https://wa.me/96171506079" target="_blank" rel="noreferrer">
                <button className="cta navbtn">
                  Book A Call
                </button>
              </a>
              <div className="mobile_menu" onClick={toggleNavbar}>
                <img
                  src={
                    showNav
                      ? require("..//assets/images/close-icon.png")
                      : require("..//assets/images/ham_menu.png")
                  }
                  alt="Mobile Menu Icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
